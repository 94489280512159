export const component = async () => {
    const slick = await import('../vendor/jquery.slick');

    var $outerGallery = $('.image-gallery--tile-list');
    var outerGalleryOptions = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        autoplay: true,
        autoplaySpeed: 8500,
    };
    var innerGalleryOptions = {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        arrows: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    autoplay: true,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,
                },
            },
        ],
    };

    // Animate the active tiles
    var animateActiveTiles = function ($gallery) {
        const $counters = $('.image-gallery--tile-list .slick-slide.slick-current.slick-active .tile-list-animated .slick-slide .counter');

        $counters.each(function () {
            $(this)
                .prop('Counter', 0)
                .animate(
                    {
                        Counter: parseFloat($(this).data('counter').toString().replace(/,/g, '')),
                    },
                    {
                        duration: 5000,
                        easing: 'linear',
                        step: function (now) {
                            $(this).text(Math.ceil(now).toLocaleString('en'));
                        },
                    }
                );
        });
    };

    // Display last updated message
    var displayLastUpdated = function () {
        if ($outerGallery.find('.counter').length > 0) {
            var dataUpdated = $outerGallery.attr('data-last-updated') || '';
            if (dataUpdated !== '') {
                var $lastUpdated = $('<div/>', {
                    class: 'image-gallery-last-updated',
                }).html($.format('Data valid as at {0}', dataUpdated));
                $outerGallery.append($lastUpdated);
            }
        }
    };

    // Layout of dots
    var controlsLayout = function () {
        // Add wrapper to control layout of dots
        $('.image-gallery--tile-list .slick-dots').wrap(
            $('<div/>', {
                class: 'image-gallery-list-dots',
            })
        );

        $('.image-gallery--tile-list .image-gallery-list-dots').prepend(
            '<button class="u-pause" role="presentation"><span>Pause Carousel</span><i class="fa fa-pause" aria-hidden="true"></i></button>'
        );
    };

    // Layout of pause play
    var pausePlayLayout = function () {
        // Add pause/play functionality to the gallery control
        $('.u-pause').on('click', function () {
            if ($outerGallery.hasClass('slick-pause')) {
                $outerGallery.slick('slickPlay');
                $(this).children('span').text('Pause carousel');
            } else {
                $outerGallery.slick('slickPause');
                $(this).children('span').text('Play carousel');

                //Pause counters and reset formats
                $outerGallery.find('.counter').each(function () {
                    $(this).stop();
                    let counter = parseInt($(this).data('counter'));
                    $(this).text(Math.ceil(counter).toLocaleString('en'));
                });
            }
            $outerGallery.toggleClass('slick-pause');
        });
    };

    // Start gallery
    $outerGallery.slick(outerGalleryOptions).on('afterChange', function (event, slick, currentSlide) {
        //Animate icons if not paused
        if (!$outerGallery.hasClass('slick-pause')) {
            animateActiveTiles($outerGallery);
        }
    });

    displayLastUpdated();
    controlsLayout();
    pausePlayLayout();

    var $tileGallery = $('.image-gallery--tile-list .slick-slide .tile-list-animated');
    if ($tileGallery.length > 0) {
        $tileGallery.slick(innerGalleryOptions);
        // ie11 fix to update/reset the svg icons
        // updateActiveTilesSVG($tileGallery);
        // Animate on load.
        animateActiveTiles($outerGallery);
        //Trigger a delayed refresh as there's some odd rendering behaviour on mobile.
        setTimeout(() => {
            $tileGallery.slick('refresh');
        }, 1000);
    }

    //Handle sidebar expand
    window.addEventListener('sidebarToggled', function () {
        $tileGallery.slick('refresh');
        $outerGallery.slick('refresh');
    });
};
